import Vue from 'vue'
import VueRouter from 'vue-router';
Vue.use(VueRouter);

// 1. Define route components.
// These can be imported from other files
import EditView from "./components/EditView.vue";
import PublishedView from "./components/PublishedView.vue";
import TableauTest from "./components/TableauTest.vue";

// 2. Define some routes
// Each route should map to a component. The "component" can
// either be an actual component constructor created via
// `Vue.extend()`, or just a component options object.
// We'll talk about nested routes later.
const routes = [
  { path: '/', name:'edit', component: EditView },
  { path: '/view/:portalId', name:'publishedLanding', component: PublishedView },
  { path: '/view/:portalId/link/:portalLink', name:'publishedView', component: PublishedView }  ,
  { path: '/tableautest', name:'tableauTest', component: TableauTest }
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
export default new VueRouter({
  routes // short for `routes: routes`
})