<template>
  <div>
    <template v-if="this.$store.state.portal == ''">
      <b-container class="welcome" :style="cssPropsInitial" fluid>
        <div class="sitemap-background">
          <div class="container sitemap">
            <div class="sitemap-content">
              Welcome to Brilliant Manager. To start building a new portal click
              on the "Create Portal" button on the left, alternatively to begin
              editing a Saved Portal, select one from the list on the left.
            </div>
          </div>
        </div>
      </b-container>
    </template>
    <template v-else>
      <div
        v-bind:class="{ 'show-navbar-preview': previewNavbar }"
        :style="cssProps"
        ref="content"
      >
        <NavBarPreview></NavBarPreview>
        <div
          class="sitemap-background"
          :class="{ dragging }"
          ref="sitemapBackground"
        >
          <template v-if="!published">
            <b-button
              size="sm"
              variant="brilliant"
              class="mb-2 sidebar-toggle"
              v-on:click="toggleSidebar"
              v-bind:class="{ rotate: sidebarCollapsed }"
            >
              <b-icon icon="chevron-double-left" aria-hidden="true"></b-icon>
              <span class="sr-only">Hide Sidebar</span>
            </b-button>
          </template>

          <div class="container sitemap">
            <div class="sitemap-content">
              <div class="row logo-container">
                <div class="col-md-10 offset-md-1">
                  <img
                    ref="logo"
                    :src="logoSrc"
                    alt="Brilliant Spaces Logo Alt Text"
                  />
                  <h1 class="sitemap-title">{{ portalTitle }}</h1>
                  <!--<button type="button" class="btn btn-default toggle-favorites" style="float: right;">
                    <span aria-hidden="true" class="glyphicon glyphicon-star-empty"></span>
                    Favorites
                  </button>-->
                </div>
              </div>
              <div class="links-container">
                <template v-if="!published">
                  <draggable
                    v-model="linkTreeAlias"
                    v-bind="dragOptions"
                    tag="div"
                    class="item-container"
                    @start="myStart"
                    @end="myEnd"
                  >
                    <sitemap-main-link-topic
                      v-for="(MainTopic, index) in linkTree"
                      v-bind:title="MainTopic.title"
                      v-bind:subheadings="MainTopic.subheadings"
                      v-bind:key="index"
                      v-bind:mainIndex="index"
                    ></sitemap-main-link-topic>
                  </draggable>
                </template>
                <template v-else>
                  <div class="item-container">
                    <sitemap-main-link-topic
                      v-for="(MainTopic, index) in linkTree"
                      v-bind:title="MainTopic.title"
                      v-bind:subheadings="MainTopic.subheadings"
                      v-bind:key="index"
                      v-bind:mainIndex="index"
                      v-bind:published="published"
                    ></sitemap-main-link-topic>
                  </div>
                </template>
              </div>
              <!--<div class="links-container favorites">
                <div class="row main-category">
                  <div class="col-md-10 offset-md-1">
                    <h2>University</h2>
                  </div>
                </div>
                <div class="row sub-category">
                  <div class="col-sm-1"></div>
                  <ul class="col-sm-2 list-unstyled">
                    <li class="sub-heading1">Peer Comparison</li>
                    <li>
                      <a
                        href="https://tableau.nyu.edu/t/SLIP/views/Rankings/NYUTrends"
                        data-helpid="4"
                        data-viewid="9487"
                        class="sitemap-link"
                        >Rankings</a
                      >
                    </li>
                  </ul>
                  <ul class="col-sm-2 list-unstyled">
                    <li class="sub-heading1">Priorities</li>
                    <li>
                      <a
                        href="https://tableau.nyu.edu/t/SLIP/views/GlobalNetwork/University-GlobalNetwork"
                        data-helpid="5"
                        data-viewid="9118"
                        class="sitemap-link"
                        >Global Network</a
                      >
                    </li>
                  </ul>
                  <ul class="col-sm-2 list-unstyled">
                    <li class="sub-heading1">Administration</li>
                    <li>
                      <a
                        href="https://tableau.nyu.edu/t/SLIP/views/UnspentFundBalances_15612362154240/FundBalances"
                        data-helpid="55"
                        data-viewid="12085"
                        class="sitemap-link"
                        >Fund Balances</a
                      >
                    </li>
                  </ul>
                </div>
                <div class="row main-category">
                  <div class="col-md-10 offset-md-1">
                    <h2>Students</h2>
                  </div>
                </div>
                <div class="row sub-category">
                  <div class="col-sm-1"></div>
                  <ul class="col-sm-2 list-unstyled">
                    <li class="sub-heading1">Demographics</li>
                    <li>
                      <a
                        href="https://tableau.nyu.edu/t/SLIP/views/StudentWho/StudentHeadcount"
                        data-helpid="13"
                        data-viewid="9085"
                        class="sitemap-link"
                        >Current Headcount</a
                      >
                    </li>
                  </ul>
                </div>
              </div>-->
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import SitemapMainLinkTopic from "./SitemapMainLinkTopic.vue";
import NavBarPreview from "./NavBarPreview.vue";
import { mapState } from "vuex";
import draggable from "vuedraggable";

export default {
  name: "PreviewPane",
  components: {
    SitemapMainLinkTopic,
    NavBarPreview,
    draggable,
  },
  props: {
    published: {
      default: false,
      type: Boolean,
    },
  },
  data: function () {
    return {
      dragging: false,
    };
  },
  mounted() {},
  computed: {
    previewNavbar: function () {
      return this.$store.state.portal.showNavBar;
    },
    dragOptions() {
      return {
        animation: 300,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    linkTreeAlias: {
      get() {
        return this.$store.state.portal.linkTree;
      },
      set(value) {
        console.log("setting", value);
        this.$store.commit("updateLinkTreeElements", value);
      },
    },
    sidebarCollapsed: function () {
      return this.$store.state.portal.sidebarCollapsed;
    },
    cssProps: function () {
      var background = "";
      if (this.$store.state.portal.backgroundChoice == "image") {
        background = this.$store.getters.backgroundImageSrcCssProp;
      } else {
        background = this.$store.state.portal.solidBackgroundColor;
      }

      return {
        "--font-color": this.$store.state.portal.fontColor,
        "--sub-heading-color": this.$store.state.portal.subHeadingColor,
        "--sitemap-background-color": this.$store.getters
          .sitemapBackgroundRGBAString,
        "--sitemap-background": background,
        "--nav-ribbon-background-color": this.$store.state.portal
          .navRibbonBackgroundColor,
        "--nav-ribbon-font-color": this.$store.state.portal.navRibbonFontColor,
        "--font-style-bold": this.$store.state.portal.textStylingBold,
        "--font-style-italic": this.$store.state.portal.textStylingItalic,
        "--font-family": this.$store.state.portal.portalFontStyle,
        "--font-size": this.$store.state.portal.portalFontSize,
        "--border-radius": this.$store.state.portal.sitemapCornerRoundingString,
      };
    },
    cssPropsInitial: function () {
      var background = "";
      if (this.$store.state.initialPortalState.backgroundChoice == "image") {
        background = this.$store.getters.initialBackgroundImageSrcCssProp;
      } else {
        background = this.$store.state.initialPortalState.solidBackgroundColor;
      }

      return {
        "--font-color": this.$store.state.initialPortalState.fontColor,
        "--sub-heading-color": this.$store.state.initialPortalState
          .subHeadingColor,
        "--sitemap-background-color": this.$store.getters
          .initialSitemapBackgroundRGBAString,
        "--sitemap-background": background,
        "--nav-ribbon-background-color": this.$store.state.portal
          .navRibbonBackgroundColor,
        "--nav-ribbon-font-color": this.$store.state.initialPortalState
          .navRibbonFontColor,
        "--font-style-bold": this.$store.state.initialPortalState
          .textStylingBold,
        "--font-style-italic": this.$store.state.initialPortalState
          .textStylingItalic,
        "--font-family": this.$store.state.initialPortalState.portalFontStyle,
        "--font-size": this.$store.state.initialPortalState.portalFontSize,
        "--border-radius": this.$store.state.initialPortalState
          .sitemapCornerRoundingString,
      };
    },
    ...mapState({
      portalTitle: (state) => state.portal.portalTitle,
      fontColor: (state) => state.portal.fontColor,
      linkTree: (state) => state.portal.linkTree,
      logoSrc: (state) => state.portal.logoSrc,
    }),
  },
  methods: {
    myStart: function (evt) {
      this.dragging = true;
      console.log("myStart", evt);
    },
    myEnd: function (evt) {
      this.dragging = false;
      console.log("myEnd", evt);
    },
    toggleSidebar: function () {
      this.$store.commit("toggleSidebar");
    },
  },
};
</script>
<style src="./PreviewPane.css"></style>

