<template>
  <form @submit.prevent="">
    
    <div class="form-group">
      <label for="portalTitle">Portal Title</label>
      <input
        type="text"
        class="form-control form-control-sm"
        id="portalTitle"
        v-model.trim="portalTitle"
        placeholder="My Portal Title"
      />
    </div>
    <div class="form-group">
      <b-button
        variant="secondary"
        v-on:click="$bubble('choose-template')"
        >Choose Template</b-button
      >
    </div>
    <div class="form-group form-inline">
      <label for="fontColor">Font-Color</label>
      <input
        type="color"
        class="form-control form-control-sm"
        id="fontColor"
        v-model="fontColor"
      />
    </div>
    <div class="form-group form-inline">
      <label for="subHeadingColor">Sub-heading Color</label>
      <input
        type="color"
        class="form-control form-control-sm"
        id="subHeadingColor"
        v-model="subHeadingColor"
      />
    </div>
    <div class="form-group form-inline">
      <label for="navRibbonBackgroundColor"
        >Navigation Ribbon Background Color</label
      >
      <input
        type="color"
        class="form-control form-control-sm"
        id="navRibbonBackgroundColor"
        v-model="navRibbonBackgroundColor"
      />
    </div>
    <div class="form-group form-inline">
      <label for="navRibbonFontColor">Navigation Ribbon Font Color</label>
      <input
        type="color"
        class="form-control form-control-sm"
        id="navRibbonFontColor"
        v-model="navRibbonFontColor"
      />
    </div>
    <div class="form-group form-inline">
      <label for="sitemapBackgroundColor"
        >Sitemap Container Background Color</label
      >
      <input
        type="color"
        class="form-control form-control-sm"
        id="sitemapBackgroundColor"
        v-model="sitemapBackgroundColor"
      />
    </div>

    <div class="form-group form-inline">
      <label for="sitemapBackgroundOpacity">Sitemap Background Opacity</label>
      <input
        type="range"
        id="sitemapBackgroundOpacity"
        min="0"
        max="100"
        step="1"
        v-model="sitemapBackgroundOpacity"
      />
      <span class="sitemap-background-opacity-value">{{
        sitemapBackgroundOpacity
      }}</span>
    </div>

    <div class="form-group form-inline">
      <label for="sitemapCornerRounding">Sitemap Corner Rounding</label>
      <input
        type="range"
        id="sitemapCornerRounding"
        min="0"
        max="100"
        step="1"
        v-model="sitemapCornerRounding"
      />
      <span>{{ sitemapCornerRounding }}px</span>
    </div>

    <div class="form-group">
      <label for="logo">Logo</label>
      <div class="custom-file">
        <input
          type="file"
          accept=".jpg, .jpeg, .png image/jpeg, image/jpg, image/png"
          ref="logoFilePicker"
          class="custom-file-input"
          id="logo"
          v-on:change="previewLogo"
        />
        <label class="custom-file-label" for="logo" ref="logoImageLabel"
          >Choose file</label
        >
      </div>
    </div>
    <b-progress
      v-if="logoUploading"
      :value="logoUploadValue"
      show-progress
      class="mb-3"
    ></b-progress>

    <fieldset class="form-group">
      <legend class="col-form-label">Background Options</legend>
      <div>
        <div class="form-group form-inline">
          <b-form-radio
            v-model="backgroundChoice"
            name="backgroundOptions"
            value="solid"
            id="solidColor"
            >Solid Color</b-form-radio
          >
          <div v-if="backgroundChoice == 'solid'">
            <input
              type="color"
              class="form-control form-control-sm"
              id="solidBackgroundColor"
              v-model="solidBackgroundColor"
            />
          </div>
        </div>

        <div>
          <b-form-radio
            v-model="backgroundChoice"
            name="backgroundOptions"
            value="image"
            id="image"
            >Background Image</b-form-radio
          >
          <div v-if="backgroundChoice == 'image'" class="form-group">
            <div class="custom-file">
              <input
                type="file"
                accept=".jpg, .jpeg, .png image/jpeg, image/jpg, image/png"
                ref="backgroundFilePicker"
                class="custom-file-input"
                id="backgroundImage"
                v-on:change="previewBackgroundImage"
              />
              <label
                class="custom-file-label"
                for="backgroundImage"
                ref="backgroundImageLabel"
                >Choose file</label
              >
            </div>
          </div>
          <b-progress
            v-if="backgroundUploading"
            :value="backgroundUploadValue"
            show-progress
            class="mb-3"
          ></b-progress>
        </div>
      </div>
    </fieldset>

    <fieldset class="form-group">
      <legend class="col-form-label">Main Topic Title Alignment</legend>
      <div class="form-group">
        <div class="row">
          <div class="form-check form-check-inline col">
            <b-icon icon="text-left" aria-hidden="true"></b-icon>
            <b-form-radio
              class="form-check-input"
              type="radio"
              name="mainTopicInlineRadioOptions"
              id="mainTopicInlineRadio1"
              v-model="mainTopicTitleAlignment"
              value="left"
            />
            <label class="form-check-label" for="mainTopicInlineRadio1"
              >Left</label
            >
          </div>
          <div class="form-check form-check-inline col">
            <b-icon icon="text-center" aria-hidden="true"></b-icon>
            <b-form-radio
              class="form-check-input"
              type="radio"
              name="mainTopicInlineRadioOptions"
              id="mainTopicInlineRadio2"
              v-model="mainTopicTitleAlignment"
              value="center"
            />
            <label class="form-check-label" for="mainTopicInlineRadio2"
              >Center</label
            >
          </div>
          <div class="form-check form-check-inline col">
            <b-icon icon="text-right" aria-hidden="true"></b-icon>
            <b-form-radio
              class="form-check-input"
              type="radio"
              name="mainTopicInlineRadioOptions"
              id="mainTopicInlineRadio3"
              v-model="mainTopicTitleAlignment"
              value="right"
            />
            <label class="form-check-label" for="mainTopicInlineRadio3"
              >Right</label
            >
          </div>
        </div>
      </div>
    </fieldset>

    <fieldset class="form-group">
      <legend class="col-form-label">Text Styling</legend>
      <div class="form-group">
        <div class="row">
          <div class="form-check form-check-inline col">
            <b-icon icon="type-bold" aria-label="Bold"></b-icon>
            <b-form-checkbox
              id="textStyleBold"
              v-model="textStylingBold"
              name="textStylingOptions"
              value="bold"
              unchecked-value="normal"
            ></b-form-checkbox>
          </div>

          <div class="form-check form-check-inline col">
            <b-icon icon="type-italic" aria-label="Italic"></b-icon>
            <b-form-checkbox
              id="textStyleItalic"
              v-model="textStylingItalic"
              name="textStylingOptions"
              value="italic"
              unchecked-value="normal"
            ></b-form-checkbox>
          </div>

          <div class="form-check form-check-inline col">
            <b-form-select
              style="width: auto"
              id="portalFontSize"
              v-model="portalFontSize"
              :options="fontsize"
              required
            ></b-form-select>
          </div>

          <div class="form-check form-check-inline col">
            <b-form-group
              id="portalFontStyle"
              label="Font"
              label-for="portalFontStyle"
            >
              <b-form-select
                id="portalFontStyle"
                v-model="portalFontStyle"
                :options="fonts"
                required
              ></b-form-select>
            </b-form-group>
          </div>
        </div>
      </div>
    </fieldset>

    <fieldset>
      <legend class="col-form-label">Custom CSS</legend>
      <div class="form-group">
        <textarea
          class="form-control"
          id="customCSS"
          rows="3"
          placeholder="Enter custom CSS code here..."
          aria-label="Enter custom CSS code here"
          v-model="cssInput"
        ></textarea>
      </div>
    </fieldset>

    <fieldset class="other-options form-group">
      <legend class="col-form-label">Other options:</legend>
      <div class="row">
        <div class="col">
          <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" id="exportImage" />
            <label class="form-check-label" for="exportImage"
              >Export Image</label
            >
          </div>
          <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" id="exportPDF" />
            <label class="form-check-label" for="exportPDF">Export PDF</label>
          </div>
          <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" id="resetView" />
            <label class="form-check-label" for="resetView">Reset View</label>
          </div>
        </div>
        <div class="col">
          <div class="form-group form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="selectCustomView"
            />
            <label class="form-check-label" for="selectCustomView"
              >Select Custom View</label
            >
          </div>
          <div class="form-group form-check">
            <input type="checkbox" class="form-check-input" id="favorites" />
            <label class="form-check-label" for="favorites">Favorites</label>
          </div>
        </div>
      </div>
    </fieldset>
    <div class="form-group form-inline">
      <b-button
        v-if="!showNavPreview"
        variant="secondary"
        v-on:click.stop.prevent="previewNav"
        >Preview Navigation Menu</b-button
      >
      <b-button v-else variant="secondary" v-on:click.stop.prevent="previewNav"
        >Hide Navigation Preview</b-button
      >
    </div>
    <div class="form-group form-inline">
      <b-button variant="secondary" v-on:click.stop.prevent>Generate</b-button>
    </div>
  </form>
</template>
<style scoped>
fieldset {
  border: 1px solid grey;
  padding: 0 10px;
}

fieldset legend {
  width: inherit;
  border-bottom: none;
}

fieldset .form-check-inline {
  padding-left: 1.25rem;
}

.sitemap-background-opacity-value {
  font-weight: bold;
  width: 30px;
  text-align: center;
}

.sitemap-rounding-corner {
  font-weight: bold;
  width: 50px;
  text-align: center;
}
</style>
<style>
/* Won't scope correctly - hence separately referenced */
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #b00000;
  background-color: #b00000;
}
</style>

<script>
export default {
  name: "DesignPropertiesForm",
  data() {
    let fontList = [
      "Lucida Console",
      "Courier New",
      "Verdana",
      "Trebuchet MS",
      "Tahoma",
      "Lucida Sans Unicode",
      "Impact",
      "Comic Sans MS",
      "Georgia",
      "Garamond",
      "Times New Roman",
      "Bookman",
      "Arial Narrow",
      "Arial Black",
      "Arial",
      "Helvetica",
      "Felix Titling",
      "Bahnschrift",
      "Algerian",
      "Papyrus",
    ];

    return {
      backgroundoptions: ["Solid Color", "Image"],
      fonts: fontList.sort(),
      fontsize: [
        "8px",
        "9px",
        "10px",
        "11px",
        "12px",
        "13px",
        "14px",
        "15px",
        "16px",
        "17px",
        "18px",
        "19px",
        "20px",
        "21px",
        "22px",
        "23px",
        "24px",
      ],
      logoUploading: false,
      logoUploadValue: 0,
      backgroundUploading: false,
      backgroundUploadValue: 0,
    };
  },
  computed: {
    uuid() {
      return this.$store.state.portal.uuid;
    },
    showNavPreview: function () {
      return this.$store.state.portal.showNavBar;
    },
    portalTitle: {
      get() {
        return this.$store.state.portal.portalTitle;
      },
      set(value) {
        this.$store.commit("updatePortalTitle", value);
      },
    },
    fontColor: {
      get() {
        return this.$store.state.portal.fontColor;
      },
      set(value) {
        this.$store.commit("updateFontColor", value);
      },
    },
    subHeadingColor: {
      get() {
        return this.$store.state.portal.subHeadingColor;
      },
      set(value) {
        this.$store.commit("updateSubHeadingColor", value);
      },
    },
    mainTopicTitleAlignment: {
      get() {
        return this.$store.state.portal.mainTopicTitleAlignment;
      },
      set(value) {
        this.$store.commit("updateMainTopicTitleAlignment", value);
      },
    },
    textStylingBold: {
      get() {
        return this.$store.state.portal.textStylingBold;
      },
      set(value) {
        this.$store.commit("updateTextStylingBold", value);
      },
    },
    textStylingItalic: {
      get() {
        return this.$store.state.portal.textStylingItalic;
      },
      set(value) {
        this.$store.commit("updateTextStylingItalic", value);
      },
    },
    navRibbonBackgroundColor: {
      get() {
        return this.$store.state.portal.navRibbonBackgroundColor;
      },
      set(value) {
        this.$store.commit("updateNavRibbonBackgroundColor", value);
      },
    },
    navRibbonFontColor: {
      get() {
        return this.$store.state.portal.navRibbonFontColor;
      },
      set(value) {
        this.$store.commit("updateNavRibbonFontColor", value);
      },
    },
    portalFontStyle: {
      get() {
        return this.$store.state.portal.portalFontStyle;
      },
      set(value) {
        this.$store.commit("updatePortalFontStyle", value);
      },
    },
    portalFontSize: {
      get() {
        return this.$store.state.portal.portalFontSize;
      },
      set(value) {
        this.$store.commit("updatePortalFontSize", value);
      },
    },
    sitemapBackgroundColor: {
      get() {
        return this.$store.state.portal.sitemapBackgroundColor;
      },
      set(value) {
        this.$store.commit("updateSitemapBackgroundColor", value);
      },
    },
    backgroundChoice: {
      get() {
        return this.$store.state.portal.backgroundChoice;
      },
      set(value) {
        this.$store.commit("updateBackgroundChoice", value);
      },
    },
    solidBackgroundColor: {
      get() {
        return this.$store.state.portal.solidBackgroundColor;
      },
      set(value) {
        console.log("updating solid color", value);
        this.$store.commit("updateSolidBackgroundColor", value);
      },
    },
    sitemapBackgroundOpacity: {
      get() {
        return this.$store.state.portal.sitemapBackgroundOpacity;
      },
      set(value) {
        this.$store.commit("updateSitemapBackgroundOpacity", value);
      },
    },

    sitemapCornerRounding: {
      get() {
        return this.$store.state.portal.sitemapCornerRounding;
      },
      set(value) {
        this.$store.commit("updateSitemapCornerRounding", value);
      },
    },
    cssInput: {
      get() {
        return this.$store.state.portal.customCSS;
      },
      set(value) {
        this.$customCssContainer.innerHTML = value;
        this.$store.commit("updateCustomCSS", value);
      },
    },
  },

  created: function () {
    this.$customCssContainer.innerHTML = this.$store.state.portal.customCSS;
  },

  methods: {
    previewNav: function () {
      this.$store.commit("toggleNavPreview");
    },
    previewLogo: function () {
      var self = this;
      var file = this.$refs.logoFilePicker.files[0];
      this.logoUploadValue = 0;
      this.logoUploading = true;
      this.$refs.logoImageLabel.textContent = file.name;
      this.$refs.logoFilePicker.disabled = true;

      var fd = new FormData();
      fd.append("image", file, self.uuid + "_logo_" + file.name);
      this.$http
        .post(
          process.env.VUE_APP_MANAGER_SERVER_URL + "/user/spaces/upload_image",
          fd,
          {
            withCredentials: true,
            onUploadProgress: (uploadEvent) => {
              this.logoUploadValue = Math.round(
                (uploadEvent.loaded / uploadEvent.total) * 100
              );
            },
          }
        )
        .then((response) => {
          const result = response.data;
          if (result.success) {
            self.logoUploading = self.$store.commit(
              "updateLogoImageSrc",
              result.image_url
            );
            self.$refs.logoFilePicker.disabled = false;
            this.$refs.logoImageLabel.textContent = "Error - please try again";
          } else {
            alert(result.error_messages.join(", "));
            console.error("Image Upload Error", result);
            self.$refs.logoFilePicker.disabled = false;
          }
          self.logoUploading = false;
        });
    },
    previewBackgroundImage: function () {
      var self = this;
      var file = this.$refs.backgroundFilePicker.files[0];
      this.backgroundUploadValue = 0;
      this.backgroundUploading = true;
      this.$refs.backgroundImageLabel.textContent = file.name;
      this.$refs.backgroundFilePicker.disabled = true;
      var fd = new FormData();
      fd.append("image", file, self.uuid + "_background_" + file.name);
      this.$http
        .post(
          process.env.VUE_APP_MANAGER_SERVER_URL + "/user/spaces/upload_image",
          fd,
          {
            withCredentials: true,
            onUploadProgress: (uploadEvent) => {
              this.backgroundUploadValue = Math.round(
                (uploadEvent.loaded / uploadEvent.total) * 100
              );
            },
          }
        )
        .then((response) => {
          const result = response.data;
          if (result.success) {
            self.$store.commit("updateBackgroundImageSrc", result.image_url);
            this.$refs.backgroundFilePicker.disabled = false;
            this.$refs.backgroundImageLabel.textContent = file.name;
          } else {
            alert(result.error_messages.join(", "));
            console.error("Image Upload Error", result);
            this.$refs.backgroundFilePicker.disabled = false;
            this.$refs.backgroundImageLabel.textContent =
              "Error - please try again";
          }
          self.backgroundUploading = false;
        });
    },
    updateSitemapBackgroundColor: function (color) {
      this.$store.commit("updateSitemapBackgroundColor", color);
    },

    updateSolidBackgroundColor: function (color) {
      this.$store.commit("updateSolidBackgroundColor", color);
    },

    updateSitemapBackgroundOpacity: function (opacity) {
      this.$store.commit("updateSitemapBackgroundOpacity", opacity);
    },
    updateSitemapCornerRounding: function (rounding) {
      this.$store.commit("updateSitemapCornerRounding", rounding);
    },
  },
};
</script>
