<template>
  <div id="app">
    <button @click="testFilters">Change Filters</button>
    <button @click="testSize">Change Size</button>
    <button @click="testExport">Export PDF</button>
    <Tableau :url="tab_url" :height="tab_height" :width="tab_width" :filters="tab_filters" :apiUrl="tab_api_url" ref="tableau"></Tableau>
  </div>
</template>

<script>
export default {
  name: 'TableauTest',
  methods: {
    testSize () {
      this.height = 500,
      this.width = 500
    },
    testFilters () {
      this.filters = {}
    },
    testExport () {
      this.$refs.tableau.viz.showExportPDFDialog()
    }
  },
  data () {
    return {
      tab_url: 'https://us-east-1.online.tableau.com/t/brilliantassociates/views/Finance/Cap_Ex?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link',
      tab_filters: {},
      tab_height: 1000,
      tab_width: 1000,
      tab_api_url:
        "https://public.tableau.com/javascripts/api/tableau-2.5.0.min.js",
    }
  }
}
</script>

<style>
</style>
