<style lang="scss">
.link-actions.btn-group-sm > .btn {
  padding: 0.1em 0.3em;
  font-size: 0.6rem;
  line-height: 0.5em;
}
.sub-category {
  .drag-handle,
  .btn-group {
    margin-right: 0em;
    margin-left: 0em;
    visibility: hidden;
    opacity: 0;
    transition-property: margin-left, margin-right, width, visibility, opacity;
    transition-duration: 0.1s;
    transition-timing-function: linear;
    width: 0em;
  }

  li:hover .btn-group {
    opacity: 1;
    visibility: visible;
    width: initial;
  }

  li:hover .drag-handle {
    margin-right: 0.3em;
    width: 1em;
    visibility: visible;
    opacity: 1;
  }
}

.item-link-wrapper,
.subheading-title {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.item-link,
.subheading-title span {
  -webkit-box-flex: 1;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
<template>
  <div class="item-link-wrapper">
    <template v-if="!published">
      <b-icon-arrows-move class="drag-handle"></b-icon-arrows-move>
      <!-- Our triggering (target) element -->
      <a class="item-link" ref="link" :href="link.url" :title="link.title">{{
        link.title
      }}</a>

      <b-button-group size="sm" class="link-actions">
        <b-button variant="secondary" :id="id" >
          <b-icon-pencil class="edit-link"></b-icon-pencil>
        </b-button>
        <b-button @click="deleteLink" variant="danger">
          <b-icon-trash-fill class="delete-link"></b-icon-trash-fill>
        </b-button>
      </b-button-group>
      <!-- Our popover title and content render container -->
      <!-- We use placement 'auto' so popover fits in the best spot on viewport -->
      <!-- We specify the same container as the trigger button, so that popover is close to button -->
      <b-popover
        :target="id"
        triggers="click"
        :show.sync="popoverShow"
        placement="auto"
        container="null"
        ref="popover"
        @show="onShow"
        @shown="onShown"
        @hidden="onHidden"
      >
        <template v-slot:title>
          <b-button @click="onClose" class="close" aria-label="Close">
            <span class="d-inline-block" aria-hidden="true"
              >&times;</span
            > </b-button
          >Link Details
        </template>

        <div>
          <b-form-group
            label="Link Title"
            label-for="popover-input-1"
            label-cols="3"
            :state="input1state"
            class="mb-1"
            description="Enter the title for the link"
            invalid-feedback="This field is required"
          >
            <b-form-input
              ref="input1"
              id="popover-input-1"
              v-model="input1"
              :state="input1state"
              size="sm"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Link URL"
            label-for="popover-input-2"
            label-cols="3"
            :state="input2state"
            class="mb-1"
            :description='(link.tableau_link ? "Tableau Server links are non-editable" : "Enter the URL for this link.")'
            invalid-feedback="This field is required"
          >
            <b-form-input 
            :disabled="link.tableau_link"
              ref="input2"
              id="popover-input-2"
              v-model="input2"
              :state="input2state"
              size="sm"
            ></b-form-input>
          </b-form-group>

          <b-alert show class="small">
            <strong>Current Values:</strong>
            <br />Link Title:
            <strong>{{ input1 }}</strong>
            <br />Link URL:
            <strong>{{ input2 }}</strong>
          </b-alert>

          <b-button @click="onClose" size="sm" variant="danger"
            >Cancel</b-button
          >
          <b-button @click="onOk" size="sm" variant="primary">Ok</b-button>
        </div>
      </b-popover>
    </template>
    <template v-else>
      <router-link class="item-link" ref="link" :to="{ name: 'publishedView', params: { portalLink: encodeURIComponent(link.url) }}" :title="link.title">{{link.title}}</router-link>
    </template>
  </div>
</template>

<script>
let uuid = 0;

export default {
  props: {
    published: {
      default: false,
      type: Boolean,
    },
    link: {},
    links: {},
  },
  name: "EditableListItem",
  data() {
    return {
      input1: "",
      input1state: null,
      input2: "",
      input2state: null,
      popoverShow: false,
      id: "editable-list-item-" + this.uuid,
    };
  },
  beforeCreate() {
    this.uuid = uuid.toString();
    uuid += 1;
  },
  watch: {
    input1(val) {
      if (val) {
        this.input1state = true;
      }
    },
    input2(val) {
      if (val) {
        this.input2state = true;
      }
    },
  },
  methods: {
    deleteLink() {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete this link.", {
          title: "Are you sure you want to delete",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Delete",
          cancelTitle: "Cancel",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((confirm) => {
          if (confirm) {
            console.log(this.link);
            this.links.splice(this.links.indexOf(this.link), 1);
          }
        })
        .catch((err) => {
          // An error occurred
          console.log(err);
        });
    },
    onClose() {
      this.popoverShow = false;
    },
    onOk() {
      if (!this.input1) {
        this.input1state = false;
      }
      if (!this.input2) {
        this.input2state = false;
      }
      if (this.input1 && this.input2) {
        this.link.title = this.input1;
        this.link.url = this.input2;
        this.onClose();
      }
    },
    onShow() {
      // This is called just before the popover is shown
      // Reset our popover form variables
      this.input1 = this.link.title;
      this.input2 = this.link.url;
      this.input1state = null;
      this.input2state = null;
      this.input1Return = "";
      this.input2Return = "";
    },
    onShown() {
      // Called just after the popover has been shown
      // Transfer focus to the first input
      this.focusRef(this.$refs.input1);
    },
    onHidden() {
      // Called just after the popover has finished hiding
      // Bring focus back to the button
      this.focusRef(this.$refs.link);
    },
    focusRef(ref) {
      // Some references may be a component, functional component, or plain element
      // This handles that check before focusing, assuming a `focus()` method exists
      // We do this in a double `$nextTick()` to ensure components have
      // updated & popover positioned first
      this.$nextTick(() => {
        this.$nextTick(() => {
          (ref.$el || ref).focus();
        });
      });
    },
  },
};
</script>