<template>
  <b-row class="row-main" no-gutters>
    <b-col class="preview-pane">
      <div v-if="errored">
        <p>
          We're sorry, we're not able to retrieve this information at the
          moment, please try back later
        </p>
      </div>
      <div v-else>
        <div v-if="loading">Loading...</div>
        <div v-else-if="$route.name == 'publishedLanding'">
          <preview-pane
            ref="previewPane"
            v-bind:published="true"
          ></preview-pane>
        </div>
        <div v-else-if="$route.name == 'publishedView'">
          <div class="published-view">
            <NavBarPreview
              ref="nav-bar"
              v-bind:published="true"
            ></NavBarPreview>
            <b-container fluid>
              <div ref="breadcrumb-container" class="breadcrumb-container">
                <b-breadcrumb>
                  <b-breadcrumb-item active>
                    {{ breadcrumb.heading }}</b-breadcrumb-item
                  >
                  <b-breadcrumb-item active>
                    {{ breadcrumb.subheading }}</b-breadcrumb-item
                  >
                  <b-breadcrumb-item active>
                    {{ breadcrumb.link_title }}</b-breadcrumb-item
                  >
                </b-breadcrumb>
                <div class="actions">
                  <b-button-toolbar key-nav aria-label="Actions Toolbar">
                    <b-button size="sm" class="mr-1" ref="favorite-button"
                      ><b-icon-heart></b-icon-heart> Add to favorites</b-button
                    >

                    <b-button
                      size="sm"
                      class="mr-1"
                      ref="reset-button"
                      @click="resetViewFilters"
                      ><b-icon-arrow-clockwise></b-icon-arrow-clockwise> Reset
                      View</b-button
                    >

                    <b-dropdown size="sm" class="mr-1" text="Export">
                      <b-dropdown-item
                        ><b-icon-file-earmark-image></b-icon-file-earmark-image>
                        Export Image</b-dropdown-item
                      >
                      <b-dropdown-item
                        ><b-icon-file-earmark></b-icon-file-earmark> Export
                        PDF</b-dropdown-item
                      >
                    </b-dropdown>
                  </b-button-toolbar>
                </div>
              </div>
              <div class="col-md-12" id="content">
                <!--<div class="embed-container" ref="embed_target"></div>-->
                <template v-if="linkItem.tableau_link">
                  <SpacesTableau
                    :url="tab_url"
                    :height="tab_height"
                    :width="tab_width"
                    :filters="tab_filters"
                    ref="tableau"
                    :apiUrl="tab_api_url"
                  ></SpacesTableau>
                </template>
                <template v-else>
                  <SpacesIframe
                    :url="tab_url"
                    :height="tab_height"
                    :width="tab_width"
                  ></SpacesIframe>
                </template>
                <hr ref="footer-ruler" class="footer-ruler to-hide" style="" />
                <footer ref="footer" class="to-hide" style="">
                  <span class="footer-title"
                    >Portal Footer Text Placeholder</span
                  >
                  <span class="footer-detail">© Portal Company 2020</span>
                </footer>
              </div>
            </b-container>
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>
<style lang="scss">
.published-view .spaces-nav {
  display: inline-block;
}
</style>

<script>
import PreviewPane from "../components/PreviewPane.vue";
import NavBarPreview from "./NavBarPreview.vue";
import SpacesIframe from "../components/SpacesIframe.vue";
import SpacesTableau from "../components/SpacesTableau.vue";

export default {
  name: "PublishedView",
  components: {
    PreviewPane,
    NavBarPreview,
    SpacesIframe,
    SpacesTableau,
  },
  data() {
    return {
      publishedPortalConfig: null,
      loading: true,
      errored: false,
      breadcrumb: false,
      linkItem: false,
      tab_url: "",
      tab_filters: {
        /*"Storm Name": ["CELIA", "BLAS", "AGATHA"],
        Basin: "East Pacific",*/
      },
      tab_height: 0,
      tab_width: "100%",
      tab_api_url:
        "https://public.tableau.com/javascripts/api/tableau-2.5.0.min.js",
    };
  },
  computed: {
    myPortalLink: function () {
      return decodeURIComponent(this.$route.params.portalLink);
    },
  },
  updated: function () {
    var that = this;
    this.$nextTick(() => {
      var windowHeight = window.innerHeight;
      var navBarHeight = that.getAbsoluteHeight(that.$refs["nav-bar"].$el);
      var breadcrumbHeight = that.getAbsoluteHeight(
        that.$refs["breadcrumb-container"]
      );
      var footerRulerHeight = that.getAbsoluteHeight(
        that.$refs["footer-ruler"]
      );
      var footerHeight = that.getAbsoluteHeight(that.$refs["footer"]);
      var myHeight = Math.floor(
        windowHeight -
          (navBarHeight + breadcrumbHeight + footerRulerHeight + footerHeight)
      );
      that.tab_height = myHeight;
    });
  },
  methods: {
    getAbsoluteHeight(el) {
      // Get the DOM Node if you pass in a string
      el = typeof el === "string" ? document.querySelector(el) : el;

      var styles = window.getComputedStyle(el);
      var margin =
        parseFloat(styles["marginTop"]) + parseFloat(styles["marginBottom"]);

      return Math.ceil(el.offsetHeight + margin);
    },
    resetViewFilters() {
      this.tab_filters = {};
    },
    myInitViz() {
      var that = this;
      if (
        typeof window.tableau != "undefined" &&
        typeof window.tableau.Viz != "undefined"
      ) {
        that.$refs.tableau.initViz();
      } else {
        console.log("tableau undefined");
        setTimeout(function () {
          that.myInitViz();
        }, 300);
      }
    },
  },
  watch: {
    $route(to, from) {
      console.log(to, from);
      // TODO combine the breadcrumb with Link return;
      this.breadcrumb = this.$store.getters.getBreadcrumb(to.params.portalLink);
      this.linkItem = this.$store.getters.getLinkItemByUrl(
        to.params.portalLink
      );
      this.tab_url = decodeURIComponent(to.params.portalLink);
      //this.myInitViz();
    },
  },
  mounted() {
    if (
      (this.$store.state.portal =
        "" || this.$store.state.portal.uuid != this.$route.params.portalId)
    ) {
      var that = this;
      this.$http
        .get(
          process.env.VUE_APP_MANAGER_SERVER_URL +
            "/spaces/portal_config/" +
            this.$route.params.portalId
        )
        .then((response) => {
          //this.info = response.data.bpi;
          console.log(response);
          that.$store.commit("setPortalConfig", response.data.config);
          if (that.$route.name == "publishedView") {
            var breadcrumb = that.$store.getters.getBreadcrumb(
              that.$route.params.portalLink
            );
            if (!breadcrumb) {
              // now revert back to landing page as no matching deep link
              console.log("deeplink not matched, going to landing page");
              that.$router.push({
                name: "publishedLanding",
                params: { portalId: that.$route.params.portalId },
              });
            } else {
              // Now embed the view
              //that.embedTableau();
              that.tab_url = decodeURIComponent(this.$route.params.portalLink);
              that.myInitViz();
              that.breadcrumb = breadcrumb;
              that.linkItem = that.$store.getters.getLinkItemByUrl(
                this.$route.params.portalLink
              );
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    } else {
      this.loading = false;
    }
  },
};
</script>
    
    