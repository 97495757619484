<style lang="scss">
.dragging .hover-target:hover,
.sortable-drag .hover-target:hover {
  border: none;
  padding: 1px;
  background-color: initial;
  .show-on-hover {
    visibility: hidden;
    opacity: 0;
    transition: none;
  }
}

.tools-wrapper {
  position: absolute;
  display: flex;
  flex-direction: row;
}

.tools-wrapper.right-justify {
  right: 0;
  flex-direction: row-reverse;
}

.action-button {
  font-size: 0.65em;
}

.show-on-hover {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s linear, opacity 0.3s linear;
}

.main-category > div {
  padding: 1px;
}

.hover-target:hover {
  border: 1px solid green;
  border-radius: 3px;
  padding: 0px;
  background-color: #ffffff36;
}

.main-topic-title-wrapper {
  display: flex;
  h2 {
    flex: auto;
  }
}

.main-topic-title-wrapper .drag-handle {
  margin: 0.25em 2em;
}

.hover-target:hover .show-on-hover {
  visibility: visible;
  opacity: 1;
}

span.delete-title {
  font-weight: bold;
}
</style>
<template>
  <div>
    <template v-if="!published">
      <div class="row main-category">
        <div class="col-md-10 offset-1 hover-target">
          <div
            class="main-topic-title-wrapper"
            v-bind:style="{ 'text-align': mainTopicTitleAlignment }"
          >
            <div
              class="tools-wrapper"
              :class="{ 'right-justify': isRightJustify }"
            >
              <b-icon-arrows-move
                class="drag-handle show-on-hover"
              ></b-icon-arrows-move>
              <b-button-toolbar class="show-on-hover">
                <b-button-group>
                  <template v-if="isRightJustify">
                    <b-button
                      class="action-button"
                      @click.stop.prevent="deleteHeading(title, mainIndex)"
                      variant="danger"
                      size="sm"
                    >
                      <b-icon-trash-fill size="sm"></b-icon-trash-fill>
                    </b-button>
                    <b-button
                      class="action-button"
                      @click.stop.prevent="addSubheading(mainIndex)"
                      variant="primary"
                      size="sm"
                    >
                      <b-icon-plus-circle-fill
                        size="sm"
                      ></b-icon-plus-circle-fill>
                    </b-button>
                  </template>
                  <template v-else>
                    <b-button
                      class="action-button"
                      @click.stop.prevent="addSubheading(mainIndex)"
                      variant="primary"
                      size="sm"
                    >
                      <b-icon-plus-circle-fill
                        size="sm"
                      ></b-icon-plus-circle-fill>
                    </b-button>
                    <b-button
                      class="action-button"
                      @click.stop.prevent="deleteHeading(title, mainIndex)"
                      variant="danger"
                      size="sm"
                    >
                      <b-icon-trash-fill size="sm"></b-icon-trash-fill>
                    </b-button>
                  </template>
                </b-button-group>
              </b-button-toolbar>
            </div>
            <h2
              contenteditable="true"
              @keydown="handleKeyDown"
              @blur="handleEndEdit"
            >
              {{ title }}
            </h2>
          </div>
        </div>
      </div>

      <draggable
        v-model="subheadingsAlias"
        v-bind="dragOptions"
        tag="div"
        class="row sub-category"
        group="sub-headings"
        filter=".ignore-elements"
      >
        <div class="col-sm-1 ignore-elements" slot="header"></div>
        <sitemap-subheading
          v-for="(subheading, index) in subheadings"
          v-bind:subheading="subheading"
          v-bind:links="subheading.links"
          v-bind:subIndex="index"
          v-bind:key="index"
          v-bind:mainIndex="mainIndex"
        ></sitemap-subheading>
      </draggable>
    </template>
    <template v-else>
      <div class="row main-category">
        <div class="col-md-10 offset-1">
          <div
            class="main-topic-title-wrapper"
            v-bind:style="{ 'text-align': mainTopicTitleAlignment }"
          >
            <h2>
              {{ title }}
            </h2>
          </div>
        </div>
      </div>
      <div class="row sub-category">
      <div class="col-sm-1 ignore-elements" slot="header"></div>
      <sitemap-subheading
        v-for="(subheading, index) in subheadings"
        v-bind:subheading="subheading"
        v-bind:links="subheading.links"
        v-bind:subIndex="index"
        v-bind:key="index"
        v-bind:mainIndex="mainIndex"
        v-bind:published="published"
      ></sitemap-subheading>
      </div>
    </template>
  </div>
</template>
<script>
import Vue from "vue";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import SitemapSubheading from "./SitemapSubheading.vue";
import { mapState } from "vuex";
import draggable from "vuedraggable";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

export default {
  name: "sitemap-main-link-topic",
  components: {
    SitemapSubheading,
    draggable,
  },
  props: {
    published: {
      default: false,
      type: Boolean,
    },
    mainIndex: {},
    title: {},
    subheadings: {},
  },
  methods: {
    addSubheading: function (index) {
      let payload = {
        message: "Updating Main Topic Heading",
        mainIndex: index,
      };
      this.$store.commit("addSubheading", payload);
    },
    deleteHeading: function (title, index) {
      var that = this;
      const h = this.$createElement;
      // Using HTML string
      const titleVNode = h("div", {
        domProps: {
          innerHTML:
            "Please confirm that you want to delete this heading <span class='delete-title'>" +
            title +
            "</span>",
        },
      });
      this.$bvModal
        .msgBoxConfirm([titleVNode], {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            console.log("deleting heading with index: " + index, value);
            let payload = {
              message: "Deleting Main Topic Heading",
              mainIndex: index,
            };
            that.$store.commit("deleteMainHeading", payload);
          }
        })
        .catch((err) => {
          console.log(err);
          // An error occurred
        });
    },
    handleEndEdit: function (evt) {
      let payload = {
        message: "Updating Main Topic Heading",
        mainIndex: this.mainIndex,
        value: evt.target.textContent,
      };
      this.$store.commit("updateMainTopicHeading", payload);
    },
    handleKeyDown: function (evt) {
      if (evt.keyCode == 13) {
        evt.preventDefault();
        evt.target.blur();
      }
    },
  },
  computed: {
    isRightJustify() {
      return this.mainTopicTitleAlignment != "right";
    },
    dragOptions() {
      return {
        animation: 150,
        group: "sub-headings",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    subheadingsAlias: {
      get() {
        return this.subheadings;
      },
      set(value) {
        console.log("setting value", value);
        console.log("setting this ", this);
        console.log("updating main-link-topic index", this.mainIndex);
        let payload = {
          message: "Updating Main Link Topic on drag/drop reorder with index",
          index: this.mainIndex,
          value: value,
        };
        this.$store.commit("updateLinkTreeMainTopic", payload);
      },
    },
    ...mapState({
      mainTopicTitleAlignment: (state) => state.portal.mainTopicTitleAlignment,
    }),
  },
};
</script>
